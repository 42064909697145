/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';

@import 'ngx-toastr/toastr';
@import 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --color-01: #f7ac44;
  --color-02: #ececeb;
  --color-03: #ffe0b2;
  --color-04: #ffcc80;
  --color-05: #363734;
  --color-06: #8a8e91;
  --color-07: #bebebe;
  --color-08: #3a3d4f;
  --color-09: #fb8c00;
  --color-10: #ffd180;
  --color-11: #3a3b450d;
  --color-12: #24252f;
  --color-13: #25424e;
  --color-14: #ffffff;
  --color-15: #ff9d5c;
  --color-16: #ec5225;
  font-size: 16px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.btn-padrao1 {
  background-color: #ec5225 !important;
  color: white !important;
  padding: 0.75em 0 !important;
  cursor: pointer !important;
}

.btn-padrao1:disabled {
  background-color: #777777 !important;
  cursor: not-allowed !important; /* O cursor será mostrado como "não permitido" quando o botão estiver desabilitado */
}

.btn-padrao2 {
  background-color: #25424e !important;
  color: white !important;
}

.btn-filtros {
  width: 100px !important;
  height: 40px !important;
  border-radius: 10px !important;
  color: var(--color-02) !important;
  background-color: var(--color-15) !important;
}

.btn-filtros:hover {
  background-color: var(--color-16) !important;
  transition: color 0.3s ease;
}

.btn-container-filtro {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button-pesquisar {
  width: 100% !important;
}
.obrigatorio {
  color: #b91c1c !important;
}

.modal {
  &_h1 {
    font-size: 16px;
    line-height: 20px;
    color: #25424e;
    margin: 40px 0;
  }
}

.mat_option {
  width: 92% !important;
}

.c-app-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_container {
    display: flex;
    flex-direction: column;
  }
  &_label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.25rem;
  }
  &_descricao {
    font-size: 1rem;
    min-width: 16rem;
    width: 50%;
    margin-bottom: 1rem;
    color: #25424e;
    @include mq(lg) {
      width: 100%;
    }
  }

  &_pesquisa {
    display: flex;
    gap: 10px !important;
  }
  &_field {
    min-width: 16rem;
    width: 50%;

    @include mq(lg) {
      width: 100%;
    }
  }
}

.c-app-tabela {
  &_cabecalho {
    font-size: 1rem !important;
    color: white !important;
    background: var(--color-13) !important;
  }
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background: var(--color-06);
  border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-07);
}

.mat-drawer-inner-container {
  overflow: hidden !important;
  -webkit-overflow-scrolling: unset !important;
}

.u-font-size-1rem {
  font-size: 1rem !important;
}

.u-font-weight-600 {
  font-weight: 600 !important;
}

.u-font-weight-700 {
  font-weight: 700 !important;
}

.u-margin-bottom-1_25rem {
  margin-bottom: 1.25rem !important;
}

.u-margin-right-0_75rem {
  margin-right: 0.75rem !important;
}

.u-resize-none {
  resize: none !important;
}

.u-width-100p {
  width: 100% !important;
}

.u-padding-1rem {
  padding: 1rem !important;
}

.is-titulo {
  margin-bottom: 1.25rem !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.mat-form-field {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    display: flex;
    align-items: center;
    background-color: #f8fafc !important;
    border: 1px solid #d1d5db !important;
    border-radius: 10px;
    height: 42px;
    width: 100%;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    display: none !important; /* Oculta a linha em todos os estados */
  }

  .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
    div.mat-mdc-autocomplete-panel {
    background-color: #f8fafc !important;
    border: 1px solid #d1d5db !important;
    border-radius: 10px;
  }
}

.readonly {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    display: flex;
    align-items: center;
    background-color: #e6e6e6 !important;
    border: 1px solid #d1d5db !important;
    border-radius: 10px;
    height: 42px;
    width: 100%;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    display: none !important; /* Oculta a linha em todos os estados */
  }

  .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
    div.mat-mdc-autocomplete-panel {
    background-color: #f8fafc !important;
    border: 1px solid #d1d5db !important;
    border-radius: 10px;
  }
}

.readonly_FluxoCarga {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    display: flex;
    align-items: center;
    background-color: #e6e6e6 !important;
    border: 1px solid #8e8e8e !important;
    border-radius: 8px;
    max-width: 690px;
    font-size: 16px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    display: none !important; /* Oculta a linha em todos os estados */
  }

  .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
    div.mat-mdc-autocomplete-panel {
    background-color: #e6e6e6 !important;
    border: 1px solid #8e8e8e !important;
    border-radius: 8px;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  height: 100%;
  width: 100%;
  appearance: none !important;
  background-color: transparent !important;
  border: none !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 0px !important;
  padding: 5px;
}

@media (max-width: 768px) {
  mat-icon {
    display: flex;
    align-items: center;
    height: 10px;
  }
  .mat-mdc-form-field-icon-suffix {
    display: flex;
  }
}

.icoReadonly {
  width: 15px !important;
  height: 15px !important;
}

.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img {
  cursor: pointer;
}
.permissao {
  margin-top: 15px;
  .mat-mdc-form-field-subscript-wrapper {
    &:not(:has(mat-error)):not(:has([matError])) {
      display: none;
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }
}

.row{
  display: flex;
  flex-direction: row;
}
